<template>
  <div v-if="order">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('order.basketSave')"
      :title="$t('order.basketUpdate')"
      @submitPressed="submitPressed"
    />
    <basket-form :order-obj="order" :action="action" @clearAction="clearAction" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import BasketForm from '@/views/Admin/Baskets/components/BasketForm.vue';

export default {
  components: {
    TitleBar,
    BasketForm
  },
  data() {
    return {
      action: null,
      order: null
    }
  },
  created() {
    this.loadOrder()
  },
  methods: {
    loadOrder() {
      const id = this.$route.params.id;
      this.$Orders.getResource({ id }).then((response) => {
        this.order = response.data
        if (!this.order.shippingAddress) {
          this.order.shippingAddress = {
            streetAddress: '',
            city: '',
            postalCode: '',
            country: '',
          }
        }
        if (!this.order.billingAddress) {
          this.order.billingAddress = {
            streetAddress: '',
            city: '',
            postalCode: '',
            country: '',
          }
        }
      });
    },
    clearAction() {
      if(this.action) {
        this.action = null;
        this.loadOrder();
      }
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>
