<template>
  <b-form @submit.prevent="onSubmit" v-if="order" class="order-form">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form-group :label="$t('order.orderURL')" label-for="h-hash">
              <b-form-input v-model="basketLink" id="h-hash" disabled />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card>
            <h5>{{ $t("navigations.products") }}</h5>
            <b-row v-if="enableEdit">
              <b-col cols="12">
                <b-form-group>
                  <label for="">{{ $t("product.singular") }}</label>
                  <v-select v-model="selectedProduct" :options="products" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group v-if="variants && variants.length">
                  <label for="">{{ $t("variant.singular") }}</label>
                  <v-select v-model="selectedVariant" :options="variants" />
                </b-form-group>
              </b-col>
              <b-col cols="12" v-if="configurations && configurations.length">
                <b-form-group>
                  <label for="">{{ $t("variant.configurations") }}</label>
                  <v-select
                    multiple
                    v-model="selectedVariantConfigurations"
                    :options="configurations"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              v-if="enableEdit"
              class="add-product-btn"
              :disabled="!selectedVariant"
              @click="addProductToOrder"
              variant="secondary"
              >{{ $t("product.add") }}
            </b-button>

            <b-alert
              class="mt-3"
              variant="danger"
              :show="v$.orderProducts.$errors.length"
              >{{ $t("order.minProductWarning") }}
            </b-alert>

            <div
              v-if="orderProducts && orderProducts.length"
              class="custom-table"
            >
              <table-lite
                :is-slot-mode="true"
                :columns="columns"
                :rows="orderProducts"
                :sortable="{}"
              >
                <template v-slot:name="data">
                  {{ data.value.name }}
                  <div
                    v-if="
                      data.value.productConfigurations &&
                      data.value.productConfigurations.length
                    "
                  >
                    <small
                      class="small"
                      v-for="(configuration, index) of data.value
                        .productConfigurations"
                      :key="index"
                    >
                      {{
                        `${configuration.name} (+${$helper.priceFormat(
                          configuration.price,
                          2
                        )})`
                      }}
                    </small>
                  </div>
                </template>
                <template v-slot:quantity="data">
                  <b-form-input
                    v-if="enableEdit"
                    type="number"
                    min="1"
                    step="1"
                    v-model="data.value.quantity"
                  />
                  <span v-else>{{ data.value.quantity }}</span>
                </template>
                <template v-slot:price="data">
                  {{ $helper.priceFormat(data.value.price) }}
                </template>
                <template v-slot:actions="data">
                  <div class="just-buttons">
                    <b-button
                      variant="secondary"
                      @click="removeOrderProduct(data.value.id)"
                    >
                      <bootstrap-icon icon="trash" size="1x" />
                    </b-button>
                  </div>
                </template>
              </table-lite>
            </div>

            <div
              v-if="orderProducts && orderProducts.length"
              class="order-total"
            >
              <div>{{ $t("order.total") }}</div>
              <div>{{ $helper.priceFormat(orderTotal, 2) }}</div>
            </div>
          </b-card>

          <b-card>
            <b-row>
              <b-col cols="12" sm="6">
                <h5>{{ $t("user.contact") }}</h5>
              </b-col>
              <b-col cols="12" sm="6"></b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('user.givenName')">
                  <b-form-input disabled v-model="order.client.givenName" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('user.familyName')">
                  <b-form-input disabled v-model="order.client.familyName" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('user.email')">
                  <b-form-input disabled v-model="order.client.email" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('user.telephone')">
                  <b-form-input disabled v-model="order.client.telephone" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.companyName')">
                  <b-form-input disabled v-model="order.client.companyName" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.ico')">
                  <b-form-input disabled v-model="order.client.ico" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.dic')">
                  <b-form-input disabled v-model="order.client.dic" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.icdph')">
                  <b-form-input disabled v-model="order.client.icdph" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card>
            <b-row>
              <b-col cols="12" sm="6">
                <h5>{{ $t("address.shipping") }}</h5>
                <b-form-group :label="$t('address.streetAddress')">
                  <b-form-input
                    disabled
                    v-model="order.shippingAddress.streetAddress"
                  />
                </b-form-group>
                <b-form-group :label="$t('address.city')">
                  <b-form-input disabled v-model="order.shippingAddress.city" />
                </b-form-group>
                <b-form-group :label="$t('address.postalCode')">
                  <b-form-input
                    disabled
                    v-model="order.shippingAddress.postalCode"
                  />
                </b-form-group>
                <b-form-group :label="$t('address.country')">
                  <b-form-input
                    disabled
                    v-model="order.shippingAddress.country"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <h5>{{ $t("address.billing") }}</h5>
                <b-form-group :label="$t('address.streetAddress')">
                  <b-form-input
                    disabled
                    v-model="order.billingAddress.streetAddress"
                  />
                </b-form-group>
                <b-form-group :label="$t('address.city')">
                  <b-form-input disabled v-model="order.billingAddress.city" />
                </b-form-group>
                <b-form-group :label="$t('address.postalCode')">
                  <b-form-input
                    disabled
                    v-model="order.billingAddress.postalCode"
                  />
                </b-form-group>
                <b-form-group :label="$t('address.country')">
                  <b-form-input
                    disabled
                    v-model="order.billingAddress.country"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import vSelect from "vue-select";
import TableLite from "vue3-table-lite";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    TableLite,
    vSelect,
  },
  props: {
    orderObj: {
      type: Object,
      default() {},
    },
    action: { type: String, default: null },
    type: { type: String, default: "update" },
  },
  data() {
    return {
      order: this.orderObj,
      title: "",
      products: [],
      variants: [],
      configurations: [],
      orderProducts: [],
      selectedProduct: null,
      selectedVariant: null,
      selectedVariantConfigurations: [],
      enableEdit: false,
      columns: [
        {
          label: this.$t("product.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("variant.quantity"),
          field: "quantity",
          sortable: false,
          width: "120px",
        },
        {
          label: this.$t("variant.sellPrice"),
          field: "price",
          sortable: false,
        },
      ],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    const validation = {
      orderProducts: { required: this.$translateError("required") },
    };
    return validation;
  },
  async created() {
    await this.getProductsList(this.products);

    if (this.order.items) {
      this.orderProducts = this.order.items;
    }

    if (this.enableEdit) {
      this.columns.push({
        label: this.$t("order.actions"),
        field: "actions",
        sortable: false,
        width: "120px",
      });
    }
  },
  watch: {
    action() {
      if (this.action) {
        this.onSubmit();
      }
    },
    orderObj() {
      this.order.price = this.orderObj.price;
    },
    selectedProduct() {
      this.selectedVariant = null;
      this.variants = [];
      if (this.selectedProduct) {
        this.getProductVariants();
      }
    },
    selectedVariant() {
      this.configurations = [];
      this.selectedVariantConfigurations = [];
      if (
        this.selectedVariant &&
        this.selectedVariant.variantObject.productConfigurations
      ) {
        this.selectedVariant.variantObject.productConfigurations.forEach(
          (c) => {
            this.configurations.push({
              label: c.name,
              id: c["@id"],
              configurationObject: c,
            });
          }
        );
      }
    },
  },
  computed: {
    user() {
      return this.order.user;
    },
    orderTotal() {
      if (this.order.id) {
        return this.order.totalPrice;
      } else {
        return this.$helper.getOrderTotal(this.orderProducts);
      }
    },
    basketLink() {
      return `https://${process.env.VUE_APP_SITE_URL}/nakupny-kosik?hash=${this.order.hash}`;
    },
  },
  methods: {
    addProductToOrder() {
      const alreadyIn = this.orderProducts.find(
        (op) => op["@id"] === this.selectedVariant.variantObject["@id"]
      );
      if (alreadyIn) {
        alreadyIn.quantity = parseInt(alreadyIn.quantity) + 1;
      } else {
        this.selectedVariant.variantObject.quantity = 1;
        this.selectedVariant.variantObject.productConfigurations =
          this.selectedVariantConfigurations.map((c) => c.configurationObject);
        this.orderProducts.push(this.selectedVariant.variantObject);
      }

      if (!this.voucher.originalPrice && this.orderProducts.length === 1) {
        this.voucher.originalPrice = this.$helper.getOrderTotal(
          this.orderProducts
        );
        this.voucher.purchasePrice = this.orderProducts[0].purchasePrice;
      }
    },
    removeOrderProduct(id) {
      const index = this.orderProducts.findIndex((op) => op.id === id);
      if (index >= 0) {
        this.orderProducts.splice(index, 1);
      }

      if (this.orderProducts.length === 0) {
        this.voucher.originalPrice = 0;
        this.voucher.purchasePrice = 0;
      }
    },
    updateOrder(id, body) {
      return this.update(
        this.$Orders,
        id,
        body,
        this.$t("order.updated"),
        null,
        this.success,
        this.error
      );
    },
    createOrder(body) {
      return this.create(
        this.$Orders,
        body,
        this.$t("order.created"),
        null,
        this.redirect,
        this.error
      );
    },
    getProductVariants() {
      this.$Products
        .getResourceByUrl({ url: `${this.selectedProduct.id}/variants` })
        .then((response) => {
          if (response.status === 200) {
            response.data["hydra:member"].forEach((variant) => {
              this.variants.push({
                label: this.$helper.buildParamString(variant.customParameters),
                id: variant["@id"],
                variantObject: variant,
              });
            });
          }
        });
    },
    getClientData() {
      this.$Users
        .getResourceByUrl({ url: this.selectedClient.id })
        .then((response) => {
          if (response.status === 200) {
            this.order.client = response.data;
          }
        });
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          client: this.order.client,
          billingAddress: this.order.billingAddress,
          shippingAddress: this.order.shippingAddress,
          items: [],
        };

        body.items = this.orderProducts.map((product) => {
          const orderItem = {
            productVariantId: product.id,
            quantity: parseInt(product.quantity),
          };
          if (product.productConfigurations) {
            orderItem.productConfigurations = product.productConfigurations.map(
              (conf) => {
                const confItem = {
                  productConfigurationId: conf.id,
                  quantity: conf.quantityAble ? 1 : parseInt(product.quantity),
                };
                return confItem;
              }
            );
          }
          return orderItem;
        });

        if (this.action === "update") {
          const id = this.$route.params.id;
          this.updateOrder(id, this.$helper.cleanPayload(body));
        }
        this.$emit("clearAction");
      } else {
        this.$emit("clearAction");
      }
    },
    redirect() {
      this.$router.push(`/baskets`);
    },
    success() {
      this.$emit("clearAction");
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
